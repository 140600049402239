@font-face {
  font-family: "Circular Std";
  src: url("../../assets/fonts/CircularStd-Medium.woff2") format("woff2"),
    url("../../assets/fonts/CircularStd-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("../../assets/fonts/Nunito-ExtraLight.woff2") format("woff2"),
    url("../../assets/fonts/Nunito-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
/* utilities css */
.line-clamp-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.line-clamp-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.line-clamp-4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
/* utilities css end */
/* navigation */

.appie-header-main-menu ul > li > a {
  color: #fff;
}
.appie-header-main-menu ul > li .sub-menu li a {
  color: #0e1133;
}
.appie-header-main-menu ul > li > a {
  color: #fff;
}
.appie-header-main-menu ul > li .sub-menu li a {
  color: #0e1133;
}
.appie-header-main-menu ul > li > a:hover {
  color: #801f82;
}
.appie-header-main-menu ul > li > a:hover {
  color: #9b2cfa;
}
.appie-header-main-menu ul > li > a {
  color: #fff;
}
.appie-header-main-menu ul > li > a:hover {
  color: #2b70fa;
}
.appie-header-main-menu ul > li .sub-menu li a {
  color: #505056;
}
.header-nav-box.header-nav-box-7 .appie-header-main-menu ul > li .sub-menu li a:hover {
  color: #2b70fa;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-header-main-menu {
    display: none;
  }
}
@media (max-width: 767px) {
  .appie-header-main-menu {
    display: none;
  }
}
.appie-header-main-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.appie-header-main-menu ul > li {
  display: inline-block;
  margin-right: 30px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-header-main-menu ul > li {
    margin-right: 14px;
  }
}
.appie-header-main-menu ul > li > a {
  font-size: 15px;
  font-weight: 500;
  color: #0e1133;
  line-height: 45px;
}
.appie-header-main-menu ul > li > a i {
  padding-left: 6px;
}
.appie-header-main-menu ul > li .sub-menu {
  position: absolute;
  left: 0;
  top: 110%;
  width: auto;
  min-width: 220px;
  max-width: 220px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all linear 0.3s;
  z-index: 99;
  -webkit-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  -moz-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  list-style-type: none;
  margin: 0;
  padding: 15px 0;
  border-radius: 5px;
  text-align: left;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .appie-header-main-menu ul > li .sub-menu {
    min-width: 250px;
    max-width: 250px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-header-main-menu ul > li .sub-menu {
    min-width: 250px;
    max-width: 250px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-header-main-menu ul > li .sub-menu {
    position: relative;
    width: 100%;
    left: 0;
    top: auto;
    opacity: 1;
    visibility: visible;
    display: none;
    right: auto;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all none ease-out 0s;
    -moz-transition: all none ease-out 0s;
    -ms-transition: all none ease-out 0s;
    -o-transition: all none ease-out 0s;
    transition: all none ease-out 0s;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: left;
    border-top: 0;
    transition: 0s;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .appie-header-main-menu ul > li .sub-menu {
    position: relative;
    width: 100%;
    left: 0;
    top: auto;
    opacity: 1;
    visibility: visible;
    display: none;
    right: auto;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all none ease-out 0s;
    -moz-transition: all none ease-out 0s;
    -ms-transition: all none ease-out 0s;
    -o-transition: all none ease-out 0s;
    transition: all none ease-out 0s;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: left;
    border-top: 0;
    transition: 0s;
    padding: 0;
  }
}
.appie-header-main-menu ul > li .sub-menu > li {
  position: relative;
  margin-left: 0;
  display: block;
}
.appie-header-main-menu ul > li .sub-menu > li .sub-menu {
  margin-left: 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-header-main-menu ul > li .sub-menu > li .sub-menu {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .appie-header-main-menu ul > li .sub-menu > li .sub-menu {
    margin-left: 0;
  }
}
.appie-header-main-menu ul > li .sub-menu > li .sub-nav-toggler {
  color: #0e1133;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.appie-header-main-menu ul > li .sub-menu > li a {
  display: block;
  padding: 0px 30px;
  position: relative;
  color: #505056;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 4px;
  margin: 0 0;
  line-height: 2.5;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .appie-header-main-menu ul > li .sub-menu > li a {
    padding: 0 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-header-main-menu ul > li .sub-menu > li a {
    padding: 0 20px;
  }
}
.appie-header-main-menu ul > li .sub-menu > li a i {
  float: right;
  font-size: 16px;
  margin-top: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-header-main-menu ul > li .sub-menu > li a i {
    display: none;
  }
}
@media (max-width: 767px) {
  .appie-header-main-menu ul > li .sub-menu > li a i {
    display: none;
  }
}
.appie-header-main-menu ul > li .sub-menu > li a .sub-nav-toggler i {
  display: inline-block;
}
.appie-header-main-menu ul > li .sub-menu > li a:hover {
  padding-left: 35px;
}
.appie-header-main-menu ul > li .sub-menu > li .sub-menu {
  right: auto;
  left: 100%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-header-main-menu ul > li .sub-menu > li .sub-menu {
    padding-left: 30px;
    -webkit-transition: all 0s ease-out 0s;
    -moz-transition: all 0s ease-out 0s;
    -ms-transition: all 0s ease-out 0s;
    -o-transition: all 0s ease-out 0s;
    transition: all 0s ease-out 0s;
  }
}
@media (max-width: 767px) {
  .appie-header-main-menu ul > li .sub-menu > li .sub-menu {
    padding-left: 30px;
    -webkit-transition: all 0s ease-out 0s;
    -moz-transition: all 0s ease-out 0s;
    -ms-transition: all 0s ease-out 0s;
    -o-transition: all 0s ease-out 0s;
    transition: all 0s ease-out 0s;
  }
}
.appie-header-main-menu ul > li .sub-menu > li .sub-menu li {
  position: relative;
}
.appie-header-main-menu ul > li .sub-menu > li .sub-menu li .sub-menu {
  right: auto;
  left: 100%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.appie-header-main-menu ul > li .sub-menu > li .sub-menu li:hover .sub-menu {
  top: 0%;
  opacity: 1;
  visibility: visible;
}
.appie-header-main-menu ul > li .sub-menu > li:hover .sub-menu {
  top: 0%;
  opacity: 1;
  visibility: visible;
}
.appie-header-main-menu ul > li .sub-menu > li:hover .sub-nav-toggler {
  color: #2b70fa;
}
.appie-header-main-menu ul > li .sub-menu > li:hover > a {
  color: #2b70fa;
}
.appie-header-main-menu ul > li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.appie-header-8-area .header-nav-box .appie-header-main-menu ul > li a:hover {
  color: #db0f30;
}
@-webkit-keyframes sticky {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
@keyframes sticky {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
.appie-header-main-menu.restly-appie-header-two > ul > li {
  padding: 34px 25px;
}
.appie-header-main-menu.restly-appie-header-two ul li a {
  color: white;
}
.main-menu.navbar-expand-lg .appie-header-main-menu > ul > li {
  padding: 34px 25px;
}
.main-menu.navbar-expand-lg .appie-header-main-menu > ul > li > a {
  color: white;
}

.main-menu.navbar-expand-lg .appie-header-main-menu.nav-home-five > ul > li > a {
  color: #0e1133;
}
.main-menu.navbar-expand-lg .appie-header-main-menu.nav-home-six > ul > li > a {
  color: #0e1133;
}
.main-menu.navbar-expand-lg .appie-header-main-menu.nav-home-six > ul > li {
  padding: 34px 20px;
}
.appie-header-main-menu ul > li > a {
  line-height: 0;
}
.appie-header-main-menu ul > li {
  margin-right: 0;
}
.nav-home-one.appie-header-main-menu ul > li {
  margin-right: 30px;
}
/* navigation end */

/* home one */
.featured-section {
  background-image: url("../../assets/images/feature/feature-bg.jpg");
}
.work-progress-area {
  background-image: url("../../assets/images/background/work-progress.jpg");
}
.main-footer {
  background-image: url("../../assets/images/footer/footer-bg-dots.png");
}
.news-section-two {
  background-image: url("../../assets/images/news/news-bg-two.jpg");
}
/* home one end */
/* home three */
.testimonial-wrap .slick-slide > div {
  margin: 0 15px;
}
.testimonial-item {
  margin: 0 0;
}
.news-section-two.news-section-three {
  background-image: none;
}
.footer-two.footer-home-three .footer-top {
  display: none;
}
/* home three end */

/* about two */
.about-section.r-com-about-section-two {
  background: none;
}
.about-section.r-com-about-section-two .section-title .sub-title {
  font-weight: 500;
  margin-bottom: 20px;
  color: #104cba;
  display: inline-block;
}
.about-section.r-com-about-section-two .about-content :is(span, p, h2, li) {
  color: #000000 !important;
}
/* about two end */
/* home 6 */
.testimonial-three-thumbs div > img {
  width: 70px !important;
  height: 70px !important;
  margin: 10px !important;
  cursor: pointer;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  border: 2px solid transparent;
}
.testimonial-three-thumbs .slick-current div > img {
  border-color: #104cba !important;
}
.testimonial-three-thumbs.quomo-testimonial-three-thumbs {
  position: absolute;
  display: flex;
  justify-content: center;
  left: calc(50% - 130px);
}
.testimonial-three-thumbs {
  bottom: 135px;
}
/* home 6 end */
/* home 7  */
.header-seven .main-menu .appie-header-main-menu li a {
  font-size: 18px;
  font-weight: 500;
  font-family: "Circular Std";
}
.header-seven .main-menu.navbar-expand-lg .appie-header-main-menu > ul > li {
  padding: 15px 15px;
}
.testimonial-four-active .slick-dots {
  position: unset;
}
.testimonial-four-author img {
  width: 110px;
  height: auto;
}
.testimonial-four-active .slick-slide > div {
  margin: 15px 15px;
}
.testimonial-four-item {
  margin: 0;
}
/* home 7 end */
.blog-four .slick-slide > div {
  margin: 15px 15px;
}
.blog-style-four {
  margin: 0;
}
.mobile-header {
  width: 100%;
  height: 60px;
  padding: 10px;
}
.mobile-header .logo-area img {
  width: 100px;
  height: auto;
}
.mobile-header .button-area span {
  color: #000000;
}
/* team page */
.team-section-three.team-page-three .section-title-with-btn {
  display: none;
}
/* team page end */
